import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Loader } from '@/loader/primary/Loader';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { programRepositoryKey } from '@/fairplayer/domain/program/ProgramRepository';
import { fromProgram, ProgramUi } from '@/fairplayer/primary/programs/Program.ui';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { useRouter } from 'vue-router';
import { ImageResolution } from '@/common/primary/ImageResolution';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'ProgramsList',

  components: { FairplayerImageVue },

  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;

    const programsList = ref(Loader.loading<ProgramUi[]>());

    onMounted(async () => {
      await loadProgramList();
      if (router.currentRoute.value.hash) {
        windowScroller.scrollToId(router.currentRoute.value.hash.substring(1));
      }
    });

    const loadProgramList = async (): Promise<void> => {
      try {
        const programListResponse = await programRepository.list(clubRepository.getCurrentSlug());
        programsList.value.loaded(programListResponse.map(program => fromProgram(program)));
      } catch (error: any) {
        logger.error('Failed to retrieve programs', error);
        programsList.value.loaded([]);
      }
    };

    return {
      ImageResolution,
      programsList,
      t,
    };
  },
});
