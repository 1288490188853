import { computed, defineComponent, PropType } from 'vue';
import { ClubUi } from '@/fairplayer/primary/club/Club.ui';
import QrcodeVue from 'qrcode.vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'LiveAuctionQrCodeCard',

  components: {
    QrCodeVue: QrcodeVue,
  },

  props: {
    club: {
      type: Object as PropType<ClubUi>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const auctionsPage = router.resolve({
      name: 'auctionsPage',
      params: {
        slug: route.params.slug,
      },
    });
    const auctionMarketplaceLink = computed(() => new URL(auctionsPage.href, props.club.webappUrl).href);

    return {
      auctionMarketplaceLink,
    };
  },
});
