import { defineComponent, PropType, ref } from 'vue';
import { CarouselVue } from '@/common/primary/carousel';
import { useI18n } from 'vue-i18n';
import { ProgramUi } from '@/fairplayer/primary/programs/Program.ui';
import { ImageResolution } from '@/common/primary/ImageResolution';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'ProgramsCarousel',

  components: { CarouselVue, FairplayerImageVue },

  props: {
    programs: {
      type: Array as PropType<ProgramUi[]>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    const currentIndex = ref(0);

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index;
    };

    return {
      ImageResolution,
      t,
      setCurrentIndex,
      currentIndex,
    };
  },
});
