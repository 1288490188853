import { PartnerUi, fromPartner } from '@/fairplayer/primary/club/Partner.ui';
import { Giveaway } from '@/fairplayer/domain/giveaway/Giveaway';
import { fromGiveawayQuestion, GiveawayQuestionUi } from '@/fairplayer/primary/club/GiveawayQuestion.ui';

export interface GiveawayUi {
  id: string;
  url: string;
  questions: GiveawayQuestionUi[];
  partner?: PartnerUi;
}

export const fromGiveaway = (giveaway: Giveaway): GiveawayUi => ({
  id: giveaway.id,
  url: giveaway.url,
  questions: giveaway.questions.map(fromGiveawayQuestion),
  partner: giveaway.partner.map(fromPartner).orElseUndefined(),
});
