import { BackendGiveawayAnswer, toGiveawayAnswer } from '@/fairplayer/secondary/club/BackendGiveawayAnswer';
import { GiveawayQuestion } from '@/fairplayer/domain/giveaway/GiveawayQuestion';
import { Optional } from '@/common/domain/Optional';

export interface BackendGiveawayQuestion {
  id: string;
  title: string;
  answers: BackendGiveawayAnswer[];
  required: boolean;
  multiline: boolean;
  preTitle?: string;
}

export const toGiveawayQuestion = (backendGiveawayQuestion: BackendGiveawayQuestion): GiveawayQuestion => ({
  id: backendGiveawayQuestion.id,
  title: backendGiveawayQuestion.title,
  answers: backendGiveawayQuestion.answers.map(toGiveawayAnswer),
  required: backendGiveawayQuestion.required,
  multiline: backendGiveawayQuestion.multiline,
  preTitle: Optional.ofEmpty(backendGiveawayQuestion.preTitle),
});
