import { computed, defineComponent, PropType } from 'vue';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LiveAuctionBiddersCard',

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const bidders = computed(
      () => new Set(props.exclusives.flatMap(exclusive => exclusive.auctionLot!.bids).map(bid => bid.bidder.id)).size
    );

    return {
      bidders,
      t,
    };
  },
});
