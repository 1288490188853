import { FairplayerButtonVue } from '@/common/primary/button';
import { NotFoundVue } from '@/common/primary/not-found';
import { partnerNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { GiveawayParticipationFormVue } from '@/fairplayer/primary/giveaway-participation/giveaway-participation-form';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { GiveawayType } from '@/fairplayer/domain/giveaway/GiveawayType';
import { giveawayRepositoryKey } from '@/fairplayer/domain/giveaway/GiveawayRepository';
import { loggerKey } from '@/common/domain/Logger';
import { fromGiveaway, GiveawayUi } from '@/fairplayer/primary/club/Giveaway.ui';
import { BannerImageVue } from '@/common/primary/banner-image';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ImageResolution } from '@/common/primary/ImageResolution';

export default {
  name: 'GiveawayParticipationPage',

  components: { BannerImageVue, ClubLogoVue, FairplayerButtonVue, NotFoundVue, GiveawayParticipationFormVue, PageVue, FairplayerImageVue },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const clubRepository = inject(clubRepositoryKey)!;
    const giveawayRepository = inject(giveawayRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const giveaways = ref(Loader.loading<GiveawayUi[]>());

    const partnerSlug = computed(() => route.query['partner'] as string);
    const partner = computed(() => club.partners.find(p => p.slug === partnerSlug.value));

    onMounted(() => loadGiveaways());

    const loadGiveaways = async (): Promise<void> => {
      try {
        const giveawaysResponse = await giveawayRepository.listFor(clubRepository.getCurrentSlug());

        giveaways.value.loaded(giveawaysResponse.filter(giveaway => giveaway.type === GiveawayType.GIVEAWAY).map(fromGiveaway));
      } catch (error: any) {
        logger.error('Failed to retrieve giveaways', error);
        giveaways.value.loaded([]);
      }
    };

    const goToMarketplace = async () => await router.push({ name: 'marketplace' });

    return {
      ImageResolution,
      club,
      giveaways,
      partner,
      partnerSlug,
      t,
      goToMarketplace,
      partnerNotFoundUi,
    };
  },
};
