import { ClubUi } from '@/fairplayer/primary/club/Club.ui';
import { MenuItemUi } from '@/common/primary/MenuItem.ui';
import { RouterTab } from '@/router/RouterTab';
import { AuctionUi } from '@/fairplayer/primary/marketplace/Auction.ui';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';

const profileItem: MenuItemUi = {
  routeName: 'profile',
  translateKey: 'profile',
  slug: 'profile',
  icon: 'user',
  routerTab: RouterTab.PROFILE,
};

const homepage: MenuItemUi = {
  routeName: 'clubHomepage',
  translateKey: 'clubHomepage',
  slug: 'club-homepage',
  icon: 'home',
  routerTab: RouterTab.HOMEPAGE,
};

const wallet: MenuItemUi = {
  routeName: 'wallet',
  translateKey: 'wallet',
  slug: 'wallet',
  icon: 'wallet',
  routerTab: RouterTab.WALLET,
};

const members: MenuItemUi = {
  routeName: 'membersList',
  translateKey: 'members',
  slug: 'members',
  icon: 'identification-badge',
  routerTab: RouterTab.MEMBERS,
};

const marketplace: MenuItemUi = {
  routeName: 'marketplace',
  translateKey: 'marketplace',
  slug: 'marketplace',
  icon: 'shopping-bag',
  routerTab: RouterTab.MARKETPLACE,
};

const news: MenuItemUi = {
  routeName: 'newsfeed',
  translateKey: 'news',
  slug: 'newsfeed',
  icon: 'calendar-check',
  routerTab: RouterTab.NEWS,
};

const auctions: MenuItemUi = {
  routeName: 'auctionsPage',
  translateKey: 'auctions',
  slug: 'auctions',
  icon: 'gavel',
  routerTab: RouterTab.AUCTIONS,
};

const donation: MenuItemUi = {
  routeName: 'donationPage',
  translateKey: 'donation',
  slug: 'donation',
  icon: 'hand-heart',
  routerTab: RouterTab.DONATION,
};

const extraMenuItems = (clubUi: ClubUi, clubPosts: PostUi[], clubAuctions: AuctionUi[]): MenuItemUi[] => {
  let extraItems: MenuItemUi[];

  if (clubUi.isFoundation) {
    extraItems = foundationExtraMenuItems(clubUi, clubAuctions);
  } else {
    extraItems = marketplaceExtraMenuItems(clubUi);
  }

  if (clubPosts.length) {
    extraItems.push(news);
  }

  return extraItems;
};

const foundationExtraMenuItems = (clubUi: ClubUi, clubAuctions: AuctionUi[]): MenuItemUi[] => {
  const items = [];

  if (clubUi.referralNeeded) {
    items.push(members);
  }

  if (clubAuctions.length) {
    const auctionsWithDot: MenuItemUi = {
      dot: clubAuctions.some(auction => auction.status === AuctionStatus.ACTIVE),
      ...auctions,
    };

    items.push(auctionsWithDot);
  }

  if (clubUi.promoteDonation) {
    items.push(donation);
  }

  return items;
};

const marketplaceExtraMenuItems = (clubUi: ClubUi) => {
  if (!clubUi.coinsEnabled) {
    return [marketplace];
  }

  return [wallet, marketplace];
};

export const createMenuItems = ({
  clubUi,
  clubAuctions,
  clubPosts,
  authenticated,
  fanValidated,
}: {
  clubUi: ClubUi;
  clubAuctions: AuctionUi[];
  clubPosts: PostUi[];
  authenticated: boolean;
  fanValidated: boolean;
}): MenuItemUi[] => {
  if (!authenticated) {
    return [homepage, ...extraMenuItems(clubUi, clubPosts, clubAuctions)];
  }

  if (!fanValidated) {
    return [];
  }

  return [homepage, ...extraMenuItems(clubUi, clubPosts, clubAuctions), profileItem];
};
