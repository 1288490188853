.fp-banner(v-if='club.vip' :class='{"-full-height": !displayAppContent}')
  BannerImageVue(:url='club.bannerUrl')
.fp-banner-with-logo(v-else :class='{"-full-height": !displayAppContent}' data-test='club-homepage-header')
  .fp-banner-with-logo--background
    BannerImageVue(:url='club.bannerUrl')
  .fp-banner-with-logo--sponsors(v-if='club.partners.length')
    .fp-pill(v-for='(partner, index) in club.partners' :key='index')
      .fp-pill--icons
        img.fp-illustration.-sm(:src='partner.logo.url')
      .fp-pill--text.-hidden-up-lg
        .fp-vertical-spacing.-s-xs
          .fp-vertical-spacing--line
            span.fp-text.-text-xs.-text-regular {{ t('homepage.sponsoredBy') }}
          .fp-vertical-spacing--line
            span.fp-text {{ partner.name }}
  .fp-banner-with-logo--logo
    ClubLogoVue(lg)
  .fp-banner-with-logo--content
    .fp-vertical-spacing.-s-lg
      .fp-vertical-spacing--line.-align-left.-align-center-up-md
        .fp-vertical-spacing.-s-xs
          .fp-vertical-spacing--line
            .fp-text.-text-3xl.-text-lg-up-lg {{ t('homepage.welcome') }}
          .fp-vertical-spacing--line
            .fp-text.-text-8xl.-text-xl-up-lg.-text-bold(data-test='platform-name') {{ club.platformName }}
      template(v-if='!displayAppContent')
        .fp-vertical-spacing--line.-justify-center-up-md.-justify-left(v-if='!authenticated')
          LoginVue(translate-key='login.loginButton' data-test='force-login')
        .fp-vertical-spacing--line.-align-left.-align-center-up-md(v-if='notReferred')
          .fp-vertical-spacing
            .fp-vertical-spacing--line
              span.fp-text.-text-3xl.-text-lg-up-lg(data-test='not-referred') {{ t('homepage.notReferred') }}
            .fp-vertical-spacing--line.-justify-center-up-md.-justify-left
              LogoutVue
      template(v-if='displayAppContent')
        template(v-if='club.isMarketplace')
          .fp-vertical-spacing--line.-justify-center-up-md.-justify-left
            .fp-vertical-spacing.-width-max-content
              .fp-vertical-spacing--line(v-if='hasExclusives')
                router-link.fp-link.-no-text-decoration(:to='{name: \'marketplace\'}' data-test='explore')
                  FairplayerButtonVue(expand secondary icon='search' :text='t("homepage.explore")')
              .fp-vertical-spacing--line(v-if='!authenticated' data-test='login-first')
                LoginVue(:redirect-to-kyc='true' :expand-button='true' translate-key='homepage.login')
        .fp-vertical-spacing--line.-justify-center-up-md.-justify-left(v-if='club.isFoundation && !auctions.isLoading() && !programs.isLoading()' data-test='foundation-links')
          .fp-vertical-spacing.-width-max-content
            .fp-vertical-spacing--line(v-for='(button, index) in foundationLinks()' :key='index')
              router-link.fp-link.-no-text-decoration(:to='{name: button.routeName}' data-test='link')
                FairplayerButtonVue(:icon='button.icon' expand :secondary='index > 0' :text='t(`homepage.browse.${button.key}`)')
