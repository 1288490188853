import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveAuctionBidVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction/exclusive-auction-bid';
import { FairplayerButtonVue } from '@/common/primary/button';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { Loader } from '@/loader/primary/Loader';
import { LoginVue } from '@/common/primary/auth/login';
import { ExclusiveAuctionCountdownVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction/exclusive-auction-countdown';
import { setupCardPaymentMethodModal } from '@/common/primary/bottom-modal/BottomModals';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { CardPaymentMethodStatus } from '@/fairplayer/domain/fan/CardPaymentMethodStatus';

export default defineComponent({
  name: 'ExclusiveAuction',

  components: {
    ExclusiveAuctionBidVue,
    ExclusiveAuctionCountdownVue,
    FairplayerButtonVue,
    LoginVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  emits: ['add-bid'],

  setup(_, { emit }) {
    const { t } = useI18n();

    const authentication = inject(authenticationKey)!;
    const bottomModalBus = inject(bottomModalBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;

    const cardPaymentNotReady = ref(false);
    const isAuthenticated = ref(Loader.loading<boolean>());

    const addBid = () => emit('add-bid');

    onMounted(async () => {
      isAuthenticated.value.loaded(await authentication.isAuthenticated());
      cardPaymentNotReady.value =
        (await fanRepository.getCardPaymentMethodFor(clubRepository.getCurrentSlug())).status !== CardPaymentMethodStatus.READY;
    });

    const openSetupCardPaymentMethodModal = () => {
      bottomModalBus.open({
        component: setupCardPaymentMethodModal(),
        titleKey: 'marketplace.setupCardPaymentMethodModal.title',
        icon: 'credit-card-plus',
      });
    };

    return {
      t,
      openSetupCardPaymentMethodModal,
      addBid,
      cardPaymentNotReady,
      isAuthenticated,
    };
  },
});
