include ../../../../common/primary/carousel/loading-carousel.mixin.pug

PageVue(v-if='!isAuthenticated.isLoading()')
  template(v-slot:header)
    ClubHomepageHeaderVue(:display-app-content='displayAppContent' :authenticated='isAuthenticated.value()' :not-referred='isNotReferred' :has-exclusives='hasExclusives' :auctions='auctions' :programs='programs')
  template(v-slot:body v-if='displayAppContent')
    .fp-vertical-spacing.-s-xl
      .fp-vertical-spacing--line(v-if='club.presentation' data-test='presentation')
        .fp-text(v-html='club.presentation.html')
      .fp-vertical-spacing--line(v-for='(linkedClub, index) in club.linkedClubs' :key='index')
        LinkedClubBannerVue(:club='linkedClub')
      .fp-vertical-spacing--line(v-if='club.state === "UPCOMING"' data-test='upcoming-alert')
        UpcomingClubAlertVue
      template(v-if='club.isMarketplace || club.isPopup')
        .fp-vertical-spacing--line(v-if='exclusives.isLoading()')
          +fp-loading-carousel
        .fp-vertical-spacing--line(v-if='hasExclusives')
          ExclusivesCarouselVue(:exclusives='carouselExclusives' :show-like='isAuthenticated.value()')
      .fp-vertical-spacing--line(v-if='club.referralNeeded && (members.isLoading() || hasMembers)' data-test='members')
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('homepage.members') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "membersList"}') {{ t('homepage.seeAll') }}
          .fp-vertical-spacing--line
            MembersCarouselVue(v-if='hasMembers' :members='members.value()')
            template(v-if='members.isLoading()')
              +fp-loading-carousel
      .fp-vertical-spacing--line(v-if='!auctions.isLoading() && hasAuctions' data-test='auctions')
        .fp-vertical-spacing(v-if='activeAuctionsWithExclusives.length > 0' data-test='active-auctions')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('homepage.activeAuctions') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "auctionsPage"}') {{ t('homepage.seeAllExclusives') }}
          .fp-vertical-spacing--line(v-for='activeAuction in activeAuctionsWithExclusives' :key='activeAuction.id')
            ActiveAuctionCarouselVue(:auction='activeAuction' :exclusives='exclusivesFor(activeAuction.id)' :show-like='isAuthenticated.value()')
        .fp-vertical-spacing(v-if='upcomingAuctionsWithExclusives.length > 0' data-test='upcoming-auctions')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('homepage.upcomingAuctions') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "auctionsPage"}') {{ t('homepage.seeAllExclusives') }}
          .fp-vertical-spacing--line(v-for='upcomingAuction in upcomingAuctionsWithExclusives' :key='upcomingAuction.id')
            UpcomingAuctionCarouselVue(:auction='upcomingAuction' :exclusives='exclusivesFor(upcomingAuction.id)' :show-like='isAuthenticated.value()')
        .fp-vertical-spacing.-s-sm(v-if='activeAuctionsWithExclusives.length === 0 && upcomingAuctionsWithExclusives.length === 0' data-test='closed-auctions')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('homepage.auctions') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "auctionsPage"}') {{ t('homepage.seeAllAuctions') }}
          .fp-vertical-spacing--line
            ExclusivesCarouselVue(:exclusives='exclusivesWithAuction' :show-like='isAuthenticated.value()')
      .fp-vertical-spacing--line(v-if='hasPosts')
        .fp-vertical-spacing(data-test='posts')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title(data-test='title') {{ t('homepage.recentNews') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "newsfeed"}') {{ t('homepage.seeAllNews') }}
          PostsListVue(v-if='hasPosts' :posts='posts.value()')
      .fp-vertical-spacing--line(v-if='hasPrograms' data-test='programs')
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line
            h2.fp-title {{ t('homepage.programs', {club: club.name}) }}
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                span.fp-text {{ t('homepage.programsSubtitle', {club: club.name}) }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "newsfeed"}') {{ t('homepage.seeAllPrograms') }}
          .fp-vertical-spacing--line
            ProgramsCarouselVue(:programs='programs.value()')
      .fp-vertical-spacing--line(v-if='club.isFoundation')
        .fp-card(data-test='one-time-donation')
          .fp-vertical-spacing
            .fp-vertical-spacing--line
              h2.fp-title {{ t('homepage.furtherSupport') }}
            .fp-vertical-spacing--line
              .fp-responsive-grid.-cols2.-cols1-up-md
                .fp-responsive-grid--column
                  span.fp-text {{ t('homepage.furtherSupportDetails') }}
                .fp-responsive-grid--column
                  .fp-vertical-spacing
                    .fp-vertical-spacing--line.-justify-center
                      router-link.fp-link.-no-text-decoration(:to='{name: \'donationPage\'}')
                        FairplayerButtonVue(:text='t(\'homepage.oneTimeDonation\')' icon='hand-heart' data-test='go-to-donation-page')
            .fp-vertical-spacing--line
              .fp-vertical-spacing.-s-sm(v-if='hasExclusiveWithoutAuctions')
                .fp-vertical-spacing--line
                  span.fp-text {{ t('homepage.oneTimeDonationSubtitle') }}
                    router-link.fp-link(:to='{name: "donationPage"}') {{ t('homepage.oneTimeDonationLink') }}
                .fp-vertical-spacing--line
                  ExclusivesCarouselVue(:exclusives='exclusivesWithoutAuction' :show-like='isAuthenticated.value()')
      template(v-if='club.isMarketplace')
        .fp-vertical-spacing--line(v-if='showTokensDocumentation' data-test='feature-card-list')
          FeatureCardListVue
        .fp-vertical-spacing--line.-justify-center(v-if='!isAuthenticated.value()')
          LoginVue(:redirect-to-kyc='true' translate-key='homepage.login' data-test='login-second')
        .fp-vertical-spacing--line
          FaqVue
