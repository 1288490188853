include ../../../../common/primary/icon/icon.mixin.pug

.fp-card
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          +fp-icon('euro', {size: '16'})
        .fp-horizontal-spacing--column.-expand
          .fp-text.-text-semi-bold {{ t('exclusivePaymentDueList.title') }}
        .fp-horizontal-spacing--column
          .fp-chip {{ exclusivePaymentDueList.length }}
    .fp-vertical-spacing--line(v-for='exclusive in exclusivePaymentDueList')
      router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug }}' :id='exclusive.id')
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            .fp-image.-md.-rounded
              ExclusiveImageVue(:url='exclusive.imageUrl' :resolution='ImageResolution.SM' highlightable data-test='exclusive-image')
          .fp-horizontal-spacing--column.-expand
            .fp-text.-text-semi-bold(data-test='exclusive-name') {{ exclusive.name }}
