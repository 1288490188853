import { DonationToCreate } from '@/fairplayer/domain/donation/DonationToCreate';

export interface BackendDonationToCreate {
  amount: number;
  successUrl: string;
  cancelUrl: string;
}

export const fromDonationToCreate = (donation: DonationToCreate): BackendDonationToCreate => ({
  amount: donation.amount.value,
  successUrl: `${donation.donationPageUrl}/payment-confirmed`,
  cancelUrl: `${donation.donationPageUrl}?payment-result=CANCELED`,
});
