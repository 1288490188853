import { ClubSelectionVue } from '@/fairplayer/primary/homepage/club-selection';
import { PageVue } from '@/fairplayer/primary/page';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Homepage',

  components: { ClubSelectionVue, PageVue },

  setup() {
    const { t } = useI18n();

    return { t };
  },
};
