import { Optional } from '@/common/domain/Optional';
import { Address } from '@/fairplayer/domain/fan/Address';

export interface BackendAddress {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  postalCode: string;
}

export const toAddress = (backendAddress: BackendAddress): Address => ({
  addressLine1: backendAddress.addressLine1,
  addressLine2: Optional.ofUndefinable(backendAddress.addressLine2),
  city: backendAddress.city,
  country: backendAddress.country,
  postalCode: backendAddress.postalCode,
});

export const fromAddress = (address: Address): BackendAddress => ({
  addressLine1: address.addressLine1,
  addressLine2: address.addressLine2.orElseUndefined(),
  city: address.city,
  country: address.country,
  postalCode: address.postalCode,
});
