import { MailToContactVue } from '@/fairplayer/primary/email';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { createTosForm } from '@/fairplayer/primary/tos/Tos.ui';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { LanguageSelectorVue } from '@/fairplayer/primary/page-footer/language-selector';
import { ThemeSelectorVue } from '@/fairplayer/primary/page-footer/theme-selector';
import { fanConsentRepositoryKey } from '@/fairplayer/domain/fan/consent/FanConsentRepository';
import { LogoutVue } from '@/common/primary/auth/logout';
import { Loader } from '@/loader/primary/Loader';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'PageFooter',

  components: {
    MailToContactVue,
    LanguageSelectorVue,
    LogoutVue,
    ThemeSelectorVue,
  },

  setup() {
    const staticFilesUri = import.meta.env.VITE_STATIC_FILES_URL;
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanConsentRepository = inject(fanConsentRepositoryKey)!;
    const authentication = inject(authenticationKey)!;
    const logger = inject(loggerKey)!;

    const isAuthenticated = ref(Loader.loading<boolean>());

    const pageFooterCollapsed = ref(false);

    const currentClub = clubRepository.findCurrentClub().map(fromClub);
    const showLanguageSelector = currentClub.map(club => club.languages.length > 1).orElse(false);
    const documents = currentClub.map(createTosForm).orElse([]);
    const isFoundation = currentClub.map(club => club.isFoundation).orElse(false);
    const isPopup = currentClub.map(club => club.isPopup).orElse(false);
    const referralNeeded = currentClub.map(club => club.referralNeeded).orElse(false);

    const clubNameOrFairplayer = currentClub.map(club => club.name).orElse('Fairplayer');
    const datedMentions = `© 2025 ${clubNameOrFairplayer}`;

    const displayConsentModal = () => fanConsentRepository.showConsentModal();

    const loadAuthenticated = async (): Promise<void> => {
      let authenticatedResponse = false;
      try {
        authenticatedResponse = await authentication.isAuthenticated();
      } catch (error: any) {
        logger.error('Failed to authenticate', error);
      } finally {
        isAuthenticated.value.loaded(authenticatedResponse);
      }
    };

    onMounted(() => loadAuthenticated());

    return {
      displayConsentModal,
      pageFooterCollapsed,
      isAuthenticated,
      isFoundation,
      isPopup,
      datedMentions,
      documents,
      referralNeeded,
      staticFilesUri,
      showLanguageSelector,
      t,
    };
  },
});
