import { BackendPartner, toPartner } from '@/fairplayer/secondary/club/BackendPartner';
import { Giveaway } from '@/fairplayer/domain/giveaway/Giveaway';
import { Optional } from '@/common/domain/Optional';
import { BackendGiveawayQuestion, toGiveawayQuestion } from '@/fairplayer/secondary/club/BackendGiveawayQuestion';
import { GiveawayType } from '@/fairplayer/domain/giveaway/GiveawayType';

export interface BackendGiveaway {
  id: string;
  type: GiveawayType;
  url: string;
  startDate: string;
  endDate: string;
  active: boolean;
  questions: BackendGiveawayQuestion[];
  partner?: BackendPartner;
}

export const toGiveaway = (backendGiveaway: BackendGiveaway): Giveaway => ({
  id: backendGiveaway.id,
  type: backendGiveaway.type,
  url: backendGiveaway.url,
  startDate: new Date(backendGiveaway.startDate),
  endDate: new Date(backendGiveaway.endDate),
  questions: backendGiveaway.questions.map(toGiveawayQuestion),
  partner: Optional.ofUndefinable(backendGiveaway.partner).map(toPartner),
});
