include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line
  .fp-horizontal-spacing.-equivalent.-s-sm
    .fp-horizontal-spacing--column
      .fp-card
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.startingPrice') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auctionLot.startingPrice.totalCost.text }}
    .fp-horizontal-spacing--column(v-if='exclusive.auctionLot.bids.length' data-test='last-bid')
      .fp-card.-highlighted
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.lastBid') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auctionLot.currentPrice.totalCost.text }}
    .fp-horizontal-spacing--column
      .fp-card
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.bids') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auctionLot.bids.length }}
ExclusiveAuctionCountdownVue(:auction='exclusive.auctionLot.auction')
.fp-vertical-spacing--line(v-if='exclusive.auctionLot.auction.status === "UPCOMING" && cardPaymentNotReady')
  .fp-alert.-info
    +fp-icon('info', {color: 'info', size: '18'})
    .fp-vertical-spacing.-s-sm
      .fp-vertical-spacing--line
        span.fp-text.-paragraph {{ t('marketplace.exclusiveDetail.auction.noPaymentMethod') }}
      .fp-vertical-spacing--line
        .fp-link(@click='openSetupCardPaymentMethodModal' data-test='register-payment-method') {{ t('marketplace.exclusiveDetail.auction.registerPaymentMethod') }}
.fp-vertical-spacing--line(v-if='exclusive.auctionLot.bids.length')
  .fp-vertical-spacing.-s-sm
    .fp-vertical-spacing--line
      h2.fp-title {{ t('marketplace.exclusiveDetail.auction.bidders') }}
    .fp-vertical-spacing--line(v-for='(bid, index) in exclusive.auctionLot.bids' :key='bid.id' :data-test='`bid-${index}`')
      ExclusiveAuctionBidVue(:bid='bid' :highlighted='index === 0')
.fp-vertical-spacing--line(v-if='!exclusive.auctionLot.bids.length')
  .fp-vertical-spacing.-s-sm
    .fp-vertical-spacing--line.-align-center
      .fp-text.-text-semi-bold {{ t('marketplace.exclusiveDetail.auction.noBidder') }}
    .fp-vertical-spacing--line.-align-center
      .fp-text {{ t('marketplace.exclusiveDetail.auction.beFirstBidder') }}
    .fp-vertical-spacing--line.-justify-center(v-if='!isAuthenticated.isLoading()')
      FairplayerButtonVue(v-if='isAuthenticated.value()' icon='gavel' :text='t("marketplace.exclusiveDetail.addBid")' secondary @click='addBid' :disabled='exclusive.auctionLot.auction.status !== \'ACTIVE\'' data-test='add-bid')
      LoginVue(v-else icon='gavel' secondary-button translate-key='marketplace.exclusiveDetail.addBid')
