import { computed, defineComponent, PropType } from 'vue';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { fromFiat } from '@/common/primary/token/Fiat.ui';
import { euro } from '@/common/domain/token/Fiat';

export default defineComponent({
  name: 'LiveAuctionTotalPriceCard',

  components: {
    FairplayerImageVue,
  },

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  setup(props) {
    const totalPrice = computed(() =>
      fromFiat(
        euro(
          props.exclusives
            .filter(e => e.auctionLot!.bids?.length > 0)
            .reduce((total, exclusive) => exclusive.auctionLot!.currentPrice.totalCost.value + total, 0)
        ),
        { fiatValueDigit: 0 }
      )
    );

    return {
      totalPrice,
    };
  },
});
