import { GiveawayParticipationToRegister } from '@/fairplayer/domain/giveaway/GiveawayParticipationToRegister';
import { InjectionKey } from 'vue';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Username } from '@/common/domain/Username';
import { GiveawayParticipation } from '@/fairplayer/domain/giveaway/GiveawayParticipation';
import { Giveaway } from '@/fairplayer/domain/giveaway/Giveaway';

export const giveawayRepositoryKey: InjectionKey<GiveawayRepository> = Symbol();

export interface GiveawayRepository {
  listFor(clubSlug: ClubSlug): Promise<Giveaway[]>;
  listParticipationsFor(clubSlug: ClubSlug, username: Username): Promise<GiveawayParticipation[]>;
  register(giveawayParticipationToRegister: GiveawayParticipationToRegister): Promise<void>;
}
