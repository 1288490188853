include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-collapsible-card
  input(type='checkbox', name='collapse' :id='cardId')
  label.fp-collapsible-card--trigger(:for='cardId')
    span.fp-text.-text-semi-bold(data-test='faq-card-questions') {{ t(`faq.${cardId}Question`) }}
    +fp-icon('chevron-right', {size: '12'})
  .fp-collapsible-card--content
    span.fp-text(v-if='!link' data-test='faq-card-answer') {{ t(`faq.${cardId}Answer`) }}
    .fp-vertical-spacing.-s-sm(v-else)
      .fp-vertical-spacing--line
        .fp-text {{ t(`faq.${cardId}Answer`) }}
      .fp-vertical-spacing--line
        a.fp-link(:href='link' :data-test='`clickable-${cardId}`') {{ t(`faq.${cardId}Link`)}}
