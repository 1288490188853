include ../../../../common/primary/select/select.mixin.pug

.fp-vertical-spacing
  .fp-vertical-spacing--line
    LogoWithTitleVue(:title='t(\'clubSelection.welcomeToFairplayer\')')
  .fp-vertical-spacing--line.-align-center
    span.fp-text {{t('clubSelection.chooseClub')}}
  template(v-if='!clubSummaries.loading')
    .fp-vertical-spacing--line(v-for='(club, index) in clubSummaries.value()' :key='index')
      router-link.fp-link.no-text-decoration(:to='{ name: \'clubHomepage\', params: { slug: club.slug } }' :data-test='`club-selector-${index}`')
        .fp-decorated-listing-element
          .fp-decorated-listing-element--logo
            FairplayerImageVue(:url='club.logoUrl' :resolution='ImageResolution.SM')
          .fp-decorated-listing-element--text
            .fp-text.-text-2xl.-text-semi-bold(v-text='club.platformName')
            .fp-icon-with-text(v-if='!clubWithLiveAuctions.isLoading() && clubWithLiveAuctions.value()[club.slug]' :data-test='`live-auction-${club.slug}`')
              .fp-dot
              .fp-icon-with-text--text {{ t('clubSelection.auctionIsActive') }}
          .fp-decorated-listing-element--banner
            FairplayerImageVue(:url='club.bannerUrl' :resolution='ImageResolution.SM')
