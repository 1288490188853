export interface ClubHomepageHeaderLinkUi {
  key: string;
  routeName: string;
  icon: string;
}

export const AUCTIONS_LINK: ClubHomepageHeaderLinkUi = {
  key: 'auctions',
  routeName: 'auctionsPage',
  icon: 'gavel',
};

export const DONATION_LINK: ClubHomepageHeaderLinkUi = {
  key: 'donation',
  routeName: 'donationPage',
  icon: 'hand-heart',
};

export const PROGRAMS_LINK: ClubHomepageHeaderLinkUi = {
  key: 'programs',
  routeName: 'newsfeed',
  icon: 'search',
};

export const NEWSFEED_LINK: ClubHomepageHeaderLinkUi = {
  key: 'newsfeed',
  routeName: 'newsfeed',
  icon: 'newspaper',
};

export const createFoundationLinks = ({
  hasAuctions,
  promoteDonation,
  hasPrograms,
}: {
  hasAuctions: boolean;
  promoteDonation: boolean;
  hasPrograms: boolean;
}): ClubHomepageHeaderLinkUi[] => {
  const buttons: ClubHomepageHeaderLinkUi[] = [];

  if (hasAuctions) {
    buttons.push(AUCTIONS_LINK);
  }

  if (promoteDonation) {
    buttons.push(DONATION_LINK);
  }

  if (hasPrograms) {
    buttons.push(PROGRAMS_LINK);
  } else {
    buttons.push(NEWSFEED_LINK);
  }

  return buttons.slice(0, 2);
};
