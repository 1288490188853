import { GoogleAnalyticsRepository } from '@/fairplayer/secondary/consent/GoogleAnalyticsRepository';
import { CookieConsentRepository } from '@/fairplayer/secondary/consent/CookieConsentRepository';
import { CookieConsentEvent } from '@/fairplayer/secondary/consent/CookieConsentEvent';
import { FanConsentRepository } from '@/fairplayer/domain/fan/consent/FanConsentRepository';
import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { BackendFanConsentRepository } from '@/fairplayer/secondary/consent/BackendFanConsentRepository';
import { VIP_PARTIAL_SLUGS } from '@/fairplayer/primary/club/Club.ui';

export class FairplayerFanConsentRepository implements FanConsentRepository {
  constructor(
    private backendFanConsentRepository: BackendFanConsentRepository,
    private clubRepository: ClubRepository,
    private cookieConsentRepository: CookieConsentRepository,
    private googleAnalyticsRepository: GoogleAnalyticsRepository,
    private window: Window
  ) {}

  init(): void {
    const clubSlug = this.clubRepository.getCurrentSlug();
    if (VIP_PARTIAL_SLUGS.some(slug => clubSlug?.includes(slug))) {
      return;
    }

    this.cookieConsentRepository
      .run()
      .then(() => this.addConsentEventListeners())
      .finally(() => this.trackingOnConsent());
  }

  showConsentModal(): void {
    this.cookieConsentRepository.show();
  }

  private addConsentEventListeners(): void {
    this.window.addEventListener('cc:onFirstConsent', (cookieConsentEvent: CookieConsentEvent) => this.saveConsent(cookieConsentEvent));
    this.window.addEventListener('cc:onChange', (cookieConsentEvent: CookieConsentEvent) => this.updateConsent(cookieConsentEvent));
  }

  private updateConsent(cookieConsentEvent: CookieConsentEvent) {
    this.cookieConsentRepository.eraseAnalyticsCookies();
    this.saveConsent(cookieConsentEvent);
  }

  private saveConsent(cookieConsentEvent: CookieConsentEvent): void {
    const { cookie } = cookieConsentEvent.detail;
    this.backendFanConsentRepository.post(cookie);
    this.trackingOnConsent();
  }

  private async trackingOnConsent(): Promise<void> {
    const cookie = this.cookieConsentRepository.retrieveConsentCookie();
    if (!cookie.categories?.includes('analytics')) {
      return await this.googleAnalyticsRepository.disableTracking();
    }

    await this.googleAnalyticsRepository.startTracking();
  }
}
