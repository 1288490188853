import { computed, defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { mobileWindowCheckerKey } from '@/common/primary/breakpoint/MobileWindowChecker';
import { ImageResolution } from '@/common/primary/ImageResolution';

export default defineComponent({
  name: 'PostsList',

  components: { FairplayerImageVue },

  props: {
    posts: {
      type: Array as PropType<PostUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const mobileWindowChecker = inject(mobileWindowCheckerKey)!;

    const limitedPosts = computed(() => {
      if (mobileWindowChecker.isMobile()) {
        return props.posts.slice(0, 2);
      }
      return props.posts.slice(0, 3);
    });

    return {
      ImageResolution,
      limitedPosts,
      t,
    };
  },
});
