import { BackendCaller } from '@/common/secondary/BackendCaller';
import { GiveawayRepository } from '@/fairplayer/domain/giveaway/GiveawayRepository';
import { GiveawayParticipationToRegister } from '@/fairplayer/domain/giveaway/GiveawayParticipationToRegister';
import { fromGiveawayParticipationToRegister } from '@/fairplayer/secondary/giveaway/BackendGiveawayParticipationToRegister';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Username } from '@/common/domain/Username';
import { GiveawayParticipation } from '@/fairplayer/domain/giveaway/GiveawayParticipation';
import { BackendGiveawayParticipation, toGiveawayParticipation } from '@/fairplayer/secondary/giveaway/BackendGiveawayParticipation';
import { BackendGiveaway, toGiveaway } from '@/fairplayer/secondary/club/BackendGiveaway';
import { Giveaway } from '@/fairplayer/domain/giveaway/Giveaway';

export class BackendGiveawayRepository implements GiveawayRepository {
  constructor(private backendCaller: BackendCaller) {}

  listFor(clubSlug: ClubSlug): Promise<Giveaway[]> {
    return this.backendCaller
      .get<BackendGiveaway[]>(`/api/clubs/${clubSlug}/giveaways`)
      .then(participations => participations.data.map(toGiveaway));
  }

  listParticipationsFor(clubSlug: ClubSlug, fanUsername: Username): Promise<GiveawayParticipation[]> {
    return this.backendCaller
      .get<BackendGiveawayParticipation[]>(`/api/clubs/${clubSlug}/fans/${encodeURIComponent(fanUsername)}/giveaway-participations`)
      .then(participations => participations.data.map(toGiveawayParticipation));
  }

  async register(giveawayParticipationToRegister: GiveawayParticipationToRegister): Promise<void> {
    await this.backendCaller.post(
      `/api/clubs/${giveawayParticipationToRegister.clubSlug}/giveaway-participations`,
      fromGiveawayParticipationToRegister(giveawayParticipationToRegister)
    );
  }
}
