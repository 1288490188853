import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { useRoute, useRouter } from 'vue-router';
import { postNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { NotFoundVue } from '@/common/primary/not-found';
import { Optional } from '@/common/domain/Optional';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { postRepositoryKey } from '@/fairplayer/domain/post/PostRepository';
import { fromPost, PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { MediaType } from '@/common/domain/MediaType';
import { FairplayerVideoVue } from '@/common/primary/fairplayer-video';
import { programRepositoryKey } from '@/fairplayer/domain/program/ProgramRepository';
import { fromProgram, ProgramUi } from '@/fairplayer/primary/programs/Program.ui';
import { DonationFormVue } from '@/fairplayer/primary/donation-form';
import { ImageResolution } from '@/common/primary/ImageResolution';

export default defineComponent({
  name: 'PostPage',

  components: {
    PageVue,
    DonationFormVue,
    NotFoundVue,
    FairplayerImageVue,
    FairplayerVideoVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const clubRepository = inject(clubRepositoryKey)!;
    const highlightedMediaBus = inject(highlightedMediaBusKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const postRepository = inject(postRepositoryKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;

    const postUi = ref(Loader.loading<PostUi | null>());
    const program = ref(Loader.loading<ProgramUi | null>());

    const currentPostSlug = computed(() => route.params.postSlug as string);
    const displayPostNotFound = computed(() => !postUi.value.isLoading() && !postUi.value.value());
    const medias = computed(() => [{ url: postUi.value.value()!.imageUrl, type: MediaType.IMAGE }, ...postUi.value.value()!.medias]);

    const club = clubRepository.getCurrentClub();
    const fanLanguage = fanLanguageRepository.getCurrentLanguage();
    const promoteDonation = club.promoteDonation;

    onMounted(async () => {
      await loadPost();
    });

    watch(
      () => [currentPostSlug.value],
      () => {
        Optional.ofEmpty(currentPostSlug.value).ifPresent(async () => {
          await loadPost();
          windowScroller.scrollToTop();
        });
      }
    );

    const loadPost = async (): Promise<void> => {
      const retrievedPost = await postRepository
        .getBySlug(club.slug, currentPostSlug.value)
        .then(optionalPost => optionalPost.map(post => fromPost(post, fanLanguage)).orElseThrow())
        .catch((error: any) => {
          logger.error('Failed to retrieve post', error);
          return null;
        });
      if (retrievedPost?.programSlug) {
        await loadPostProgram(retrievedPost!.programSlug);
      }
      postUi.value.loaded(retrievedPost);
    };

    const loadPostProgram = async (programSlug: string): Promise<void> => {
      program.value.loaded(
        await programRepository
          .getBySlug(club.slug, programSlug)
          .then(optionalProgram => optionalProgram.map(program => fromProgram(program)).orElseThrow())
          .catch((error: any) => {
            logger.error('Failed to retrieve program', error);
            return null;
          })
      );
    };

    const goToNewsfeed = async (): Promise<void> => {
      await router.push({ name: 'newsfeed' });
    };

    const highlightPostMedias = (activeIndex: number) => {
      highlightedMediaBus.open({
        medias: medias.value,
        activeIndex,
      });
    };

    return {
      ImageResolution,
      highlightPostMedias,
      displayPostNotFound,
      postUi,
      program,
      promoteDonation,
      goToNewsfeed,
      postNotFoundUi,
      t,
    };
  },
});
