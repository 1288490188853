include ../../../common/primary/icon/icon.mixin.pug

.fp-top-menu
  router-link.fp-top-menu-item(v-for='(menuItem, index) in menuItems' :key='index' :class='{"-active": currentRouterTabNames.includes(menuItem.routerTab)}' :to='{ name: menuItem.routeName}' :data-test='`top-menu.${menuItem.slug}`')
    span(v-if='menuItem.routeName !== "profile"' data-test='text') {{ t(`menu.${menuItem.translateKey}`) }}
    span.fp-button--icon(v-else data-test='icon')
      +fp-icon('user', {size: '24'})
    .fp-top-menu-item--dot(v-if='menuItem.dot' data-test='dot')
      .fp-dot.-sm
  LoginVue(v-if='!isAuthenticated' data-test='top-menu.login' translate-key='homepage.login')
    template(v-slot:link)
      a.fp-top-menu-item(:class='{"-active": currentRouterTabNames.includes(RouterTab.LOGIN)}')
        +fp-icon('log-in', {size: '24'})
