import { useI18n } from 'vue-i18n';
import { computed, defineComponent, inject, onMounted, PropType, Ref, ref } from 'vue';
import { FairplayerButtonVue } from '@/common/primary/button';
import { useRoute, useRouter } from 'vue-router';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { euro } from '@/common/domain/token/Fiat';

export default defineComponent({
  name: 'DonationForm',

  components: {
    FairplayerButtonVue,
  },

  props: {
    spotlightExclusives: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const logger = inject(loggerKey)!;

    const amount: Ref<number | undefined> = ref();
    const isCreatingOneTimeDonation = ref(false);

    const isAuthenticated = ref(Loader.loading<boolean>());

    const paymentCanceled = computed(() => route.query['payment-result'] === 'CANCELED');
    const title = computed(() => `${t('oneTimeDonation.contribute')}${props.spotlightExclusives ? '...' : ''}`);

    const createDonation = async () => {
      if (!isAuthenticated.value.value()) {
        return router.push({ name: 'login' });
      }

      isCreatingOneTimeDonation.value = true;
      const clubSlug = clubRepository.getCurrentSlug();
      const donationPageUrl = computeDonationPageUrl();
      donationRepository
        .create({ clubSlug, amount: euro(+amount.value!), donationPageUrl })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch((error: any) => {
          logger.error('Failed to create donation', error);
          isCreatingOneTimeDonation.value = false;
        });
    };

    const computeDonationPageUrl = () => {
      const club = clubRepository.getCurrentClub();

      const donationPage = router.resolve({
        name: 'donationPage',
        params: {
          slug: route.params.slug,
        },
      });

      return new URL(donationPage.href, club.webappUrl).href;
    };

    onMounted(async () => {
      await authentication.isAuthenticated().then(authenticated => {
        isAuthenticated.value.loaded(authenticated);
      });
    });

    return {
      paymentCanceled,
      amount,
      createDonation,
      isAuthenticated,
      isCreatingOneTimeDonation,
      t,
      title,
    };
  },
});
