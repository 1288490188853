import { defineComponent, PropType } from 'vue';
import { ClubSummaryUi } from '@/fairplayer/primary/homepage/club-selection/ClubSummary.ui';
import { useI18n } from 'vue-i18n';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ImageResolution } from '@/common/primary/ImageResolution';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';

export default defineComponent({
  name: 'LinkedClubBanner',

  components: {
    FairplayerImageVue,
  },

  props: {
    club: {
      type: Object as PropType<ClubSummaryUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      FairplayerFallbackImageUi,
      ImageResolution,
      t,
    };
  },
});
