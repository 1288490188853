import { computed, inject, onMounted, onUnmounted, ref, ShallowRef, TransitionGroup, useTemplateRef } from 'vue';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { Club } from '@/fairplayer/domain/club/Club';
import { maxCardSize, maxSquaresIn } from '@/fairplayer/primary/live-auction/maxCardSize';
import { LiveAuctionCardVue } from '@/fairplayer/primary/live-auction/live-auction-card';
import { LiveAuctionExclusiveCardVue } from '@/fairplayer/primary/live-auction/live-auction-exclusive-card';
import { LiveAuctionTotalPriceCardVue } from '@/fairplayer/primary/live-auction/live-auction-total-price-card';
import { LiveAuctionQrCodeCardVue } from '@/fairplayer/primary/live-auction/live-auction-qr-code-card';
import { LiveAuctionCardUi } from '@/fairplayer/primary/live-auction/LiveAuctionCard.ui';
import { LiveAuctionClubNameCardVue } from '@/fairplayer/primary/live-auction/live-auction-club-name-card';
import { LiveAuctionPlatformNameCardVue } from '@/fairplayer/primary/live-auction/live-auction-platform-name-card';
import { LiveAuctionBiddersCardVue } from '@/fairplayer/primary/live-auction/live-auction-bidders-card';
import { LiveAuctionBidsCardVue } from '@/fairplayer/primary/live-auction/live-auction-bids-card';
import { LiveAuctionExclusivesCardVue } from '@/fairplayer/primary/live-auction/live-auction-exclusives-card';
import { auctionRepositoryKey } from '@/fairplayer/domain/exclusive/AuctionRepository';
import { Loader } from '@/loader/primary/Loader';

export default {
  name: 'LiveAuctionPage',

  components: {
    LiveAuctionCardVue,
    TransitionGroup,
  },

  setup() {
    const auctionRepository = inject(auctionRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const newestAuctionId = ref(Loader.loading<string | undefined>());
    const grid: ShallowRef<HTMLElement | null> = useTemplateRef('grid');
    const club = ref();
    const previousExclusives = ref<ExclusiveUi[]>([]);
    const exclusives = ref<ExclusiveUi[]>([]);
    const refreshExclusivesIntervalId = ref(0);
    const cardPxSize = ref(0);
    const maxSquares = ref(0);

    const extraCards: LiveAuctionCardUi[] = [
      { component: LiveAuctionTotalPriceCardVue },
      { component: LiveAuctionPlatformNameCardVue, head: true },
      { component: LiveAuctionBidsCardVue },
      { component: LiveAuctionBiddersCardVue },
      { component: LiveAuctionClubNameCardVue },
      { component: LiveAuctionExclusivesCardVue },
    ];

    const cards = computed<LiveAuctionCardUi[]>(() => {
      if (!cardPxSize.value) {
        return [];
      }

      const allCards: LiveAuctionCardUi[] = exclusives.value.map(e => ({
        component: LiveAuctionExclusiveCardVue,
        exclusive: e,
        shaking: newBid(e.id),
      }));

      const toFill = Math.min(maxSquares.value - exclusives.value.length - 1, extraCards.length);

      for (let i = 0; i < toFill; i++) {
        const extraCard = extraCards[i];
        if (extraCard.head) {
          allCards.unshift(extraCard);
        } else {
          allCards.push(extraCard);
        }
      }

      allCards.push({ component: LiveAuctionQrCodeCardVue });

      return allCards;
    });

    const newBid = (exclusiveId: string) =>
      previousExclusives.value.length > 0 &&
      exclusives.value.find(e => e.id === exclusiveId)!.auctionLot!.bids.length >
        (previousExclusives.value.find(e => e.id === exclusiveId)?.auctionLot?.bids?.length ?? 0);

    const sortByAuctionPriceDescThenAlphabetical = (e1: ExclusiveUi, e2: ExclusiveUi) => {
      const dif = e2.auctionLot!.currentPrice.totalCost.value - e1.auctionLot!.currentPrice.totalCost.value;
      if (dif === 0) {
        return e1.name.localeCompare(e2.name);
      }
      return dif;
    };

    const autoRefreshAuctionExclusives = (exclusives: ExclusiveUi[]) => {
      if (refreshExclusivesIntervalId.value || !exclusives.length) {
        return;
      }
      refreshExclusivesIntervalId.value = globalWindow.setInterval(loadAuctionExclusives, 4000);
    };

    const loadAuctions = async (): Promise<void> => {
      try {
        const auctionsResponse = await auctionRepository.listByClub(club.value.slug);
        newestAuctionId.value.loaded(auctionsResponse[0]?.id);
      } catch (error: any) {
        newestAuctionId.value.loaded(undefined);
        logger.error('Failed to retrieve auctions', error);
      }
    };

    const loadAuctionExclusives = async (): Promise<void> => {
      try {
        const exclusivesResponse = await exclusiveRepository.listBySlug(club.value.slug);
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.auctionLot.filter(lot => lot.auction.id === newestAuctionId.value.value()).isPresent())
          .map(exclusive => fromExclusive(exclusive, club.value))
          .sort(sortByAuctionPriceDescThenAlphabetical);
        autoRefreshAuctionExclusives(mappedExclusives);
        const tmpExclusives = exclusives.value;
        exclusives.value = mappedExclusives;
        computeMaxCardSize();
        previousExclusives.value = tmpExclusives;
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value = [];
      }
    };

    const computeMaxCardSize = () => {
      const width = grid.value!.getBoundingClientRect().width;
      const height = grid.value!.getBoundingClientRect().height;
      cardPxSize.value = maxCardSize(width, height, exclusives.value.length + 1);
      maxSquares.value = maxSquaresIn(width, height, cardPxSize.value);
    };

    onMounted(() => {
      clubRepository.retrieveClub().then((retrievedClub: Club) => {
        club.value = retrievedClub;
        return loadAuctions().then(loadAuctionExclusives);
      });
      globalWindow.addEventListener('resize', computeMaxCardSize);
    });

    onUnmounted(() => {
      if (refreshExclusivesIntervalId.value) {
        globalWindow.clearInterval(refreshExclusivesIntervalId.value);
      }
      globalWindow.removeEventListener('resize', computeMaxCardSize);
    });

    return {
      cards,
      grid,
      club,
      exclusives,
      cardPxSize,
    };
  },
};
