import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubSummary } from '@/fairplayer/domain/club/ClubSummary';
import { ClubSummaryUi, fromClubSummary } from '@/fairplayer/primary/homepage/club-selection/ClubSummary.ui';
import { LogoWithTitleVue } from '@/fairplayer/primary/logo-with-title';
import { Loader } from '@/loader/primary/Loader';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { loggerKey } from '@/common/domain/Logger';
import { auctionRepositoryKey } from '@/fairplayer/domain/exclusive/AuctionRepository';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { ImageResolution } from '@/common/primary/ImageResolution';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default {
  name: 'ClubSelection',

  components: { LogoWithTitleVue, FairplayerImageVue },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const auctionRepository = inject(auctionRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const selectedClubSlug = ref('');
    const clubSummaries = ref(Loader.loading<ClubSummaryUi[]>());
    const clubWithLiveAuctions = ref(Loader.loading<Record<string, boolean>>());

    const redirectToClub = () => (slug: ClubSlug) => router.replace({ name: 'clubHomepage', params: { slug } });

    const loadClubSummaries = async () => {
      let clubSummariesResponse: ClubSummary[] = [];
      try {
        clubSummariesResponse = await clubRepository.list();
      } catch (error: any) {
        logger.error('Failed to retrieve club list', error);
      } finally {
        clubSummaries.value.loaded(clubSummariesResponse.map(fromClubSummary));
      }
    };

    const loadAuctions = async () => {
      const auctionStatus: Record<string, boolean> = {};
      for (const club of clubSummaries.value.value()) {
        try {
          const auctions = await auctionRepository.listByClub(club.slug);
          auctionStatus[club.slug] = auctions.some(a => a.status === AuctionStatus.ACTIVE);
        } catch (error: any) {
          logger.error('Failed to retrieve auctions', error);
        }
      }
      return clubWithLiveAuctions.value.loaded(auctionStatus);
    };

    const loadClubsAndAuctions = () => async () => {
      await loadClubSummaries();
      await loadAuctions();
    };

    onMounted(async () => clubRepository.findSelectedSlug().ifPresentOrElse(redirectToClub(), loadClubsAndAuctions()));

    return {
      ImageResolution,
      t,
      clubWithLiveAuctions,
      clubSummaries,
      selectedClubSlug,
    };
  },
};
