export class GoogleAnalyticsRepository {
  async startTracking() {
    const { addGtag, optIn } = await import('vue-gtag');
    addGtag();
    optIn();
  }

  async disableTracking() {
    const { optOut } = await import('vue-gtag');
    optOut();
  }
}
