import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { ImageResolution } from '@/common/primary/ImageResolution';

export default defineComponent({
  name: 'ProgramPostsList',

  components: { FairplayerImageVue },

  props: {
    posts: {
      type: Array as PropType<PostUi[]>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      ImageResolution,
      t,
    };
  },
});
