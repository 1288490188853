export enum RouterTab {
  AUCTIONS = 'AUCTIONS',
  DONATION = 'DONATION',
  HOMEPAGE = 'HOMEPAGE',
  LOGIN = 'LOGIN',
  MARKETPLACE = 'MARKETPLACE',
  MEMBERS = 'MEMBERS',
  NEWS = 'NEWS',
  PROFILE = 'PROFILE',
  WALLET = 'WALLET',
}
