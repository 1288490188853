import { defineComponent, PropType } from 'vue';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LiveAuctionExclusivesCard',

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
