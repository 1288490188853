import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { GiveawayType } from '@/fairplayer/domain/giveaway/GiveawayType';
import { Loader } from '@/loader/primary/Loader';
import {
  CardGiveawayUi,
  fromGiveaway,
} from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/giveaway-card/CardGiveaway.ui';
import { loggerKey } from '@/common/domain/Logger';
import { giveawayRepositoryKey } from '@/fairplayer/domain/giveaway/GiveawayRepository';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ImageResolution } from '@/common/primary/ImageResolution';

export default defineComponent({
  name: 'GiveawayCard',

  components: {
    FairplayerButtonVue,
    FairplayerImageVue,
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const giveawayRepository = inject(giveawayRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const club = clubRepository.getCurrentClub();

    const giveaways = ref(Loader.loading<CardGiveawayUi[]>());

    onMounted(() => loadGiveaways());

    const loadGiveaways = async (): Promise<void> => {
      try {
        const giveawaysResponse = await giveawayRepository.listFor(clubRepository.getCurrentSlug());

        const cardGiveaways = giveawaysResponse
          .filter(giveaway => giveaway.type === GiveawayType.GIVEAWAY)
          .map(giveaway => fromGiveaway(giveaway, club));
        giveaways.value.loaded(cardGiveaways);
      } catch (error: any) {
        logger.error('Failed to retrieve giveaways', error);
        giveaways.value.loaded([]);
      }
    };

    return { ImageResolution, t, giveaways };
  },
});
