import { defineComponent, PropType } from 'vue';
import { PostUi } from '@/fairplayer/primary/home/club-homepage/posts-list/Post.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ImageResolution } from '@/common/primary/ImageResolution';

export default defineComponent({
  name: 'PostCard',

  components: {
    FairplayerImageVue,
  },

  props: {
    post: {
      type: Object as PropType<PostUi>,
      required: false,
    },
  },

  setup() {
    return {
      ImageResolution,
    };
  },
});
