include ../../../../../common/primary/icon/icon.mixin.pug

.fp-sticky-pane(data-test='exclusive-detail')
  aside.fp-sticky-pane--aside
    .fp-display
      .fp-display--image
        ExclusiveMediaVue(:media='medias[selectedMedia]' @dblclick='highlightMedia')
        .fp-display--image-mask(v-if='exclusive.isOutOfStock || exclusive.isUpcoming')
          .fp-badge(v-if='exclusive.isUpcoming && exclusive.upcomingDate' data-test='upcoming-exclusive-badge') {{ t('marketplace.upcomingExclusiveWithDate', { date: d(exclusive.upcomingDate.toDate(), { month: 'long', day: 'numeric' })}) }}
          .fp-badge(v-else-if='exclusive.isUpcoming' data-test='upcoming-exclusive-badge-without-date') {{ t('marketplace.upcomingExclusive') }}
          .fp-badge(v-else data-test='out-of-stock-badge') {{ t('marketplace.outOfStock') }}
        .fp-display--left-action(@click='goBack' data-test='go-back')
          +fp-icon('arrow-left', {size: '16'})
        .fp-display--right-actions(v-if='showLike')
          .fp-display--action(v-if='!localLiked' @click.stop='like' data-test='like')
            +fp-icon('heart', {size: 16})
          .fp-display--action(v-else @click.stop='unlike' data-test='unlike')
            +fp-icon('heart-filled', {size: 16, pop: true, color: 'danger'})
          .fp-display--action(@click='highlightMedia' data-test='highlight-media')
            +fp-icon('widen', {size: 16})
      .fp-display--thumbnails(v-if='medias.length > 1' data-test='exclusive-detail-thumbnails')
        template(v-for='(media, index) in medias' :key='index')
          FairplayerImageVue.fp-display--thumbnail-image(v-if='media.type === \'IMAGE\'' :url='media.url' :resolution='ImageResolution.SM' :active='media.url === medias[selectedMedia].url' @click='selectMedia(index)' :data-test='`exclusive-detail-thumbnail-${index}`')
          .fp-display--thumbnail-video(v-else @click='selectMedia(index)' :class='{"-active": media.url === medias[selectedMedia].url}' :data-test='`exclusive-detail-thumbnail-${index}`')
            FairplayerVideoVue(:url='media.url' expand)
  .fp-sticky-pane--content
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        ExclusiveDetailHeadingVue(:exclusive='exclusive')
      .fp-vertical-spacing--line
        .fp-text(v-html='exclusive.description.html' data-test='description')
      .fp-vertical-spacing--line(v-if='exclusive.taxDeduction' data-test='tax-deduction')
        .fp-alert.-success
          +fp-icon('check', {color: 'success', size: '18'})
          template(v-if='exclusive.auctionLot')
            span.fp-text.-paragraph(v-if='exclusive.taxDeduction.nonDeductibleAmount.value === 0' data-test='auction-full-tax-deduction') {{ t('marketplace.auctionFullTaxDeductionAvailable') }}
            span.fp-text.-paragraph(v-else data-test='auction-tax-deduction-beyond-amount') {{ t('marketplace.auctionTaxDeductionBeyond', { amount: exclusive.taxDeduction.nonDeductibleAmount.text }) }}
          template(v-if='!exclusive.auctionLot')
            span.fp-text.-paragraph(v-if='exclusive.taxDeduction.nonDeductibleAmount.value === 0' data-test='full-tax-deduction') {{ t('marketplace.fullTaxDeductionAvailable') }}
            span.fp-text.-paragraph(v-else data-test='tax-deduction-of') {{ t('marketplace.taxDeductionOf', { amount: taxDeductionAvailableText }) }}
      ExclusiveAuctionVue(v-if='exclusive.auctionLot' :exclusive='exclusive' @add-bid='addBid')
      BookingSlotsVue(v-if='club.isPopup')
      GiveawayCardVue
