import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ImageResolution } from '@/common/primary/ImageResolution';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';

export default defineComponent({
  name: 'ClubLogo',

  components: {
    FairplayerImageVue,
  },

  props: {
    lg: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const logoUrl = clubRepository.getCurrentClub().logoUrl;

    return {
      FairplayerFallbackImageUi,
      ImageResolution,
      logoUrl,
      t,
    };
  },
});
