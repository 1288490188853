import { defineComponent, inject, onMounted, onUnmounted, PropType, ref, useTemplateRef } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { pageScrollBusKey } from '@/common/domain/page-scroll/PageScrollBus';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ImageResolution } from '@/common/primary/ImageResolution';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BannerImage',

  components: {
    FairplayerImageVue,
  },

  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const pageScrollBus = inject(pageScrollBusKey)!;

    const banner = useTemplateRef('banner');
    const bannerTranslateY = ref(0);
    let unsubscribePageScrollBus: Unsubscribe;

    const updateBannerTranslateY = (px: number) => {
      bannerTranslateY.value = px * 0.5;
    };

    onMounted(() => {
      unsubscribePageScrollBus = pageScrollBus.onScroll(updateBannerTranslateY);
    });

    onUnmounted(() => {
      unsubscribePageScrollBus();
    });

    return {
      FairplayerFallbackImageUi,
      ImageResolution,
      banner,
      bannerTranslateY,
      t,
    };
  },
});
