include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    NotFoundVue(v-if='displayPostNotFound' :not-found-ui='postNotFoundUi()')
    .fp-sticky-pane(v-if='!postUi.isLoading() && postUi.value()' data-test='post')
      .fp-sticky-pane--content
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-display.-wide(data-test='image')
              .fp-display--image
                FairplayerImageVue(:url='postUi.value().imageUrl' :resolution='ImageResolution.LG' :alt='postUi.value().name' @click='highlightPostMedias(0)')
                .fp-display--left-action(@click='goToNewsfeed' data-test='go-to-newsfeed')
                  +fp-icon('arrow-left', {size: '16'})
          .fp-vertical-spacing--line
            .fp-vertical-spacing.-s-xl
              .fp-vertical-spacing--line
                .fp-vertical-spacing.-s-sm
                  .fp-vertical-spacing--line
                    .fp-text.-color-shade-500 {{ postUi.value().date }}
                  .fp-vertical-spacing--line
                    h1.fp-title(data-test='name') {{ postUi.value().name }}
                  .fp-vertical-spacing--line
                    span.fp-text(v-html='postUi.value().description.html' data-test='description')
              .fp-vertical-spacing--line(v-if='promoteDonation')
                DonationFormVue
          .fp-vertical-spacing--line
            router-link.fp-link.-no-text-decoration(v-if='!program.isLoading() && program.value()' data-test='program' :to='{name: \'programPage\', params: { programSlug: program.value().slug }}')
              .fp-featured-content
                FairplayerImageVue(:url='program.value().imageUrl' :resolution='ImageResolution.LG' :alt='program.value().name')
                .fp-featured-content--background
                .fp-featured-content--description
                  h3.fp-title(data-test='program-name') {{ program.value().name }}
                  .fp-text.-lines-1 {{ program.value().description.raw }}
      aside.fp-sticky-pane--aside(v-if='postUi.value().medias.length')
        .fp-vertical-spacing(data-test='medias')
          .fp-vertical-spacing--line(v-for='(media, index) in postUi.value().medias' :key='index')
            .fp-image.-rounded.-expand.-wide(v-if='media.type === \'IMAGE\'')
              FairplayerImageVue(:url='media.url' :resolution='ImageResolution.MD' :alt='postUi.value().name' @click='highlightPostMedias(index + 1)')
            FairplayerVideoVue(v-else :url='media.url' @click='highlightPostMedias(index + 1)' wide)
