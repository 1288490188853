.fp-responsive-grid.-cols2.-cols1-up-md
  .fp-responsive-grid--column
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        h2.fp-title {{ t('faq.faqTitle') }}
      .fp-vertical-spacing--line
        .fp-text {{ t('faq.faqSubtitle') }}
      .fp-vertical-spacing--line
        FaqCardVue(card-id='exclusiveDefinition')
      .fp-vertical-spacing--line(v-if='club.coinsEnabled' data-test='token-definition')
        FaqCardVue(card-id='tokenDefinition')
  .fp-responsive-grid--column
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        h2.fp-title {{ t('faq.otherQuestionsTitle') }}
      .fp-vertical-spacing--line
        .fp-text {{ t('faq.otherQuestionsSubtitle') }}
      .fp-vertical-spacing--line(data-test='switch-club')
        FaqCardVue(card-id='switchClub' :link='clubSelectionLink')
      .fp-vertical-spacing--line(v-if='club.coinsEnabled' data-test='exclusive-in-fiat')
        FaqCardVue(:card-id='notEnoughTokensDefinitionId')
