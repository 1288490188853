include ../../../common/primary/icon/icon.mixin.pug

.fp-horizontal-menu
  router-link.fp-horizontal-menu-item(v-for='(menuItem, index) in menuItems' :key='index' :class='{"-active": currentRouterTabNames.includes(menuItem.routerTab)}' :to='{ name: menuItem.routeName}' :data-test='`horizontal-menu.${menuItem.slug}`')
    .fp-horizontal-menu-item--icon
      i.fp-icon.-s24(:class='`fp-glyph-${menuItem.icon}`')
    span.fp-horizontal-menu-item--text {{ t(`menu.${menuItem.translateKey}`) }}
    .fp-horizontal-menu-item--dot(v-if='menuItem.dot' data-test='dot')
      .fp-dot.-sm
  LoginVue(v-if='!isAuthenticated' :class='{"-active": currentRouterTabNames.includes(RouterTab.PROFILE)}' data-test='horizontal-menu.profile')
    template(v-slot:link)
      .fp-horizontal-menu-item(:class='{"-active": currentRouterTabNames.includes(RouterTab.LOGIN)}')
        .fp-horizontal-menu-item--icon
          +fp-icon('log-in', {size: '24'})
        span.fp-horizontal-menu-item--text {{ t('menu.login') }}
